<template>
  <div>
    <div
      style="
        border-bottom: 1px solid #dcdfe6;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15px 24px;
        text-align: left;
        font-size: 18px;
        background:#fff;
      "
    >
      <div class="top-left">
        <img src="https://file.lgbfss.com/www/official/images/logo1.png" alt />
      </div>
      <div class="top-right">
        <a href="https://www.lgbfss.com/website/?id=1">首页</a>
        <a href="https://www.lgbfss.com/website/maker?id=3">产品与服务</a>
        <a href="https://www.lgbfss.com/website/news?id=6">新闻资讯</a>
        <a href="https://www.lgbfss.com/website/about_us?tab=4&id=7">关于我们</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Top'
}
</script>
<style lang="scss">
.top-left {
  width: 136px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}
.top-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #131824;
  width: 50%;
  a {
    color: #000;
    text-decoration: none;
  }
}
</style>
<template>
  <div>
    <div class="bottom_box">
      <div class="bottom_border">
        <div class="bottom_left">
          <div>全国统一热线</div>
          <h4>{{ stylePhone }}</h4>
          <p>地址：{{ styleAddress }}</p>
          <!-- <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="text-decoration: none;color: #444;"
          >©陕西零工保信息科技有限公司 ALL RIGHTS RESERVED 陕ICP备19002438号</a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    stylePhone: String,
    styleAddress: String
  },
  data () {

    return {

    }
  },
}
</script>

<style>
</style>